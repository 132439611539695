<template>
  <v-dialog v-model="visibility" width="720px" persistent>
    <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
    <div style="padding: 20px; background-color: #fff">
      <v-row
        no-gutters
        style="border-bottom: 1px solid #e5e9eb; padding-bottom: 20px"
      >
        <span
          style="
            color: #1b1b1b;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
          "
          >{{
            !isNextStep && !isEdit ? "Створення промокоду" : `${promocode.name}`
          }}</span
        >
        <v-row no-gutters justify="end" v-if="isEdit">
          <v-btn
            class="promocodeActionBtn"
            style="color: #ffc107; background: rgba(255, 193, 7, 0.1)"
            >Призупинити</v-btn
          >
          <v-btn
            class="promocodeActionBtn"
            style="color: #df3a3a; background: rgba(223, 58, 58, 0.1)"
            @click="$emit('deletePromocode', promocode.id)"
            >Видалити</v-btn
          >
        </v-row>
        <v-row no-gutters justify="end" v-else>
          <v-icon color="#1B1B1B" @click="$emit('close')" size="32px"
            >mdi-close</v-icon
          >
        </v-row>
      </v-row>
      <v-col class="px-0 py-0" v-if="!isNextStep">
        <v-text-field
          outlined
          dense
          height="50px"
          style="border-radius: 10px; margin-top: 32px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Назва промокоду"
          placeholder="Введіть назву.."
          v-model="promocode.name"
          :error-messages="nameError"
        />
        <v-autocomplete
          outlined
          dense
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Тип"
          placeholder="Виберіть тип"
          :items="Object.values(promocodePeriodList)"
          :item-text="'name'"
          :item-value="'value'"
          v-model="promocode.promocode_type"
          :error-messages="promocodeTypeError"
        />
        <v-autocomplete
          outlined
          dense
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Виберіть на що діє промокод"
          placeholder="Вибіреть дію"
          :items="Object.values(typePromocodeActionList)"
          :item-text="'name'"
          :item-value="'value'"
          v-model="promocode.route_type"
          :error-messages="routeTypeError"
        />
      </v-col>
      <v-col v-else class="px-0 py-0">
        <v-row no-gutters>
          <v-col class="py-0" cols="8" style="padding-right: 10px">
            <v-autocomplete
              outlined
              dense
              height="50px"
              style="border-radius: 10px; margin-top: 32px"
              color="#C0C0C0"
              background-color="#FCFCFC"
              label="Тип знижки"
              placeholder="Виберіть тип"
              v-model="promocode.amount_type"
              :error-messages="amountTypeError"
              :items="promocodeType"
              :item-text="'name'"
              :item-value="'value'"
            />
          </v-col>
          <v-col class="py-0" cols="4" style="padding-left: 10px">
            <v-text-field
              outlined
              dense
              height="50px"
              style="border-radius: 10px; margin-top: 32px"
              color="#C0C0C0"
              background-color="#FCFCFC"
              :label="
                promocode.amount_type == 'Percent'
                  ? 'Відсоток знижки'
                  : 'Cума(грн)'
              "
              :placeholder="
                promocode.amount_type == 'Percent'
                  ? 'Введіть %..'
                  : 'Введіть суму(грн)'
              "
              v-model="promocode.amount"
              :error-messages="amountError"
            />
          </v-col>
        </v-row>
        <v-text-field
          outlined
          dense
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Ключ промокоду"
          placeholder="Введіть ключ.."
          v-model="promocode.value"
          :error-messages="valueError"
        />
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              height="50px"
              style="border-radius: 10px"
              color="#C0C0C0"
              background-color="#FCFCFC"
              label="Період дії початку\закінчення"
              placeholder="Виберіть період"
              v-model="promocode.dates"
              :error-messages="datesError"
              v-bind="attrs"
              v-on="on"
              v-if="
                promocode.promocode_type == 'Selected' ||
                promocode.promocode_type == 'Ticket_count'
              "
            />
          </template>
          <div style="background: #fff">
            <v-row no-gutters justify="center">
              <v-date-picker
                v-model="promocode.dates"
                range
                color="#144FA9"
                no-title
                :key="datePickerKey"
                :min="minDate"
              ></v-date-picker>
            </v-row>
          </div>
        </v-menu>
        <v-autocomplete
          v-if="
            promocode.route_type == 'Selected' ||
            promocode.route_type == 'Schedule'
          "
          outlined
          dense
          multiple
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Вибір маршруту"
          placeholder="Виберіть маршрут"
          :items="routesList"
          :item-text="
            (item) =>
              `${item?.departure?.translations?.name}-${item?.destination?.translations?.name}`
          "
          :item-value="'id'"
          v-model="promocode.routes"
          :error-messages="routesError"
        />
        <v-autocomplete
          v-if="promocode.route_type == 'Schedule'"
          outlined
          dense
          multiple
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Вибір рейсу"
          placeholder="Виберіть рейс"
          v-model="promocode.schedules"
          :items="Object.values(scheduleList)"
          :item-text="
            (item) =>
              `${new Date(item.departed_at).toLocaleDateString('uk-UA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                 timeZone: 'Europe/Kiev'
              })} - ${new Date(item.arrival_at).toLocaleDateString('uk-UA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                 timeZone: 'Europe/Kiev'
              })} (${item?.route?.departure?.translations?.name}-${
                item?.route?.destination?.translations?.name
              })`
          "
          :item-value="'id'"
          :disabled="promocode.routes == ''"
          :error-messages="routesError"
        />
        <v-row no-gutters align="center">
          <v-col cols="11" class="px-0 py-0">
            <v-text-field
              outlined
              dense
              height="50px"
              style="border-radius: 10px"
              color="#C0C0C0"
              background-color="#FCFCFC"
              label="Кількість"
              placeholder="Введіть кількість.."
              v-model="promocode.usage_limit"
              hide-details
            />
          </v-col>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="helpIcon"
                style="margin-left: 8px"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            Введіть, щоб обмежити кількість використань
          </v-tooltip>
        </v-row>
      </v-col>
      <submit-button
        width="100%"
        style="
          margin-top: 32px;
          height: 48px !important;
          font-size: 20px;
          font-weight: 700;
        "
        :disabled="isInvalidData"
        @click="
          isNextStep
            ? isEdit
              ? updatePromocode()
              : createPromocode()
            : (isNextStep = true)
        "
        >{{ isNextStep ? (isEdit ? "Оновити" : "Створити") : "Далі" }}
      </submit-button>
      <v-row no-gutters justify="center" style="margin-top: 12px">
        <cancel-btn text="Скасувати" @click="$emit('close')" />
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import requestFormData from "@/requests/requestFormData";
import promocodeService from "../../../../requests/Admin/Discount/promocodeService";
import tripService from "../../../../requests/Admin/tripService";
import submitButton from "../../../UI/Buttons/submitButton.vue";
import cancelBtn from "../../../UI/Buttons/cancelBtn.vue";
import routesService from "../../../../requests/Admin/routesService";
import ErrorSnackbar from "../../../UI/Snackbars/errorSnackbar.vue";
import debounce from "lodash/debounce";
export default {
  components: { cancelBtn, submitButton, ErrorSnackbar },
  mixins: [modalMixin, validationMixin],
  data: () => ({
    promocode: {
      name: "",
      promocode_type: "",
      route_type: "",
      amount_type: "Percent",
      amount: "",
      routes: [-1],
      schedules: [-1],
      dates: [-1],
      value: "",
      usage_limit: 0,
    },
    promocodePeriodList: [
      { id: 1, name: "Заданий період", value: "Selected" },
      { id: 2, name: "Перманентний", value: "Permanent" },
      //{ id: 3, name: "За кількість куплених квитків", value: "Ticket_count" },
    ],
    typePromocodeActionList: [
      { id: 1, name: "На всі маршрути", value: "All" },
      { id: 2, name: `На маршрут/Маршрути`, value: "Selected" },
      { id: 3, name: `На окремий рейс`, value: "Schedule" },
    ],
    promocodeType: [
      { name: "Відсоток від вартості", value: "Percent" },
      { name: "Сума від вартості", value: "Fixed" },
    ],
    routesList: [],
    scheduleList: [],
    isNextStep: false,
    page: 1,
    datePickerKey: 1,
    isCycle: true,
    showErrorSnackbar: false,
    snackbarText: "",
  }),
  validations: {
    promocode: {
      name: {
        required,
      },
      promocode_type: {
        required,
      },
      route_type: {
        required,
      },
      amount_type: { required },
      value: {
        required,
      },
      dates: { required },
      amount: {
        required,
      },
      routes: {
        required,
      },
    },
  },
  props: {
    isEdit: {
      require: false,
    },
    promocodeForEdit: {
      require: false,
    },
  },
  mounted() {
    if (this.isEdit) {
      this.setPromocode();
    }
    this.getRoutes();
  },
  methods: {
    async getRoutes() {
      while (this.isCycle) {
        await routesService.getRoutes(this.page, "All").then((res) => {
          if (res.status == "Success") {
            this.routesList.push(...res.data);
            if (res.pagination.meta.last_page == this.page) {
              this.isCycle = false;
            } else {
              this.page++;
              this.isCycle = true;
            }
          }
        });
      }
    },
    async getSheduls() {
      this.scheduleList = [];
      this.promocode.routes.forEach(async (route_id) => {
        if (route_id !== -1) {
          await tripService.getTripsByRouteID(route_id).then((res) => {
            if (res.status == "Success") {
              this.scheduleList.push(...res.data);
            }
          });
        }
      });
    },
    handleCheckRoute: debounce(function () {
      this.getSheduls();
    }, 1000),
    async createPromocode() {
      this.$v.$touch();
      let tenYears = new Date();
      tenYears.setFullYear(tenYears.getFullYear() + 10);
      if (!this.isInvalidData) {
        this.promocode.routes = this.promocode.routes.filter(
          (route) => route !== -1
        );
        this.promocode.schedules = this.promocode?.schedules?.filter(
          (schedule) => schedule !== -1
        );
        this.promocode.routes = this.promocode.routes.map(
          (route) => (route = { route_uuid: route })
        );
        this.promocode.schedules = this.promocode.schedules.map(
          (schedule) => (schedule = { schedule_uuid: schedule })
        );
        let data = {
          amount: this.promocode.amount * 100,
          amount_type: this.promocode.amount_type,
          route_type: this.promocode.route_type,
          routes: this.promocode.routes.length ? this.promocode.routes : "",
          schedules: this.promocode.schedules.length
            ? this.promocode.schedules
            : "",
          usage_limit: this.promocode.usage_limit
            ? this.promocode.usage_limit
            : null,
          status: "Active",
          code: this.promocode.value,
          started_at:
            this.promocode.dates.length > 0 &&
            this.promocode.dates[0] !== -1 &&
            this.promocode.dates[0] !== ""
              ? `${new Date(this.promocode.dates[0])
                  .toISOString()
                  .substring(0, 10)} 00:00:00`
              : `${new Date().toISOString().substring(0, 10)} 00:00:00`,
          ended_at:
            this.promocode.dates.length > 1 &&
            this.promocode.dates[1] !== -1 &&
            this.promocode.dates[1] !== ""
              ? `${new Date(this.promocode.dates[1])
                  .toISOString()
                  .substring(0, 10)} 23:59:00`
              : `${new Date(tenYears).toISOString().substring(0, 10)} 23:59:00`,
          translations: [
            {
              title: this.promocode.name,
              description: this.promocode.name,
              lang: "ua",
            },
          ],
        };
        if (this.promocode.routes == -1) {
          delete data.routes;
        }
        let form = requestFormData.jsonToFormData(data);
        await promocodeService
          .createPromocode(form)
          .then((res) => {
            if (res.status == "Success") {
              this.$emit("getPromocodes");
              this.$emit("close");
            }
          })
          .catch((e) => {
            if (
              e &&
              e.response &&
              e.response.data &&
              e.response.data.errors.code == "The code has already been taken."
            ) {
              this.snackbarText = "Цей ключ вже використано.";
              this.showErrorSnackbar = true;
              setTimeout(() => {
                this.showErrorSnackbar = false;
              }, 3000);
            }
          });
      }
      //this.$emit("close");
    },
    async updatePromocode() {
      this.$v.$touch();
      let tenYears = new Date();
      tenYears.setFullYear(tenYears.getFullYear() + 10);
      let routes = [];
      let schedules = [];
      if (this.promocode.route_type == "Selected") {
        this.promocode.routes.forEach((route) => {
          if (typeof route == "number") {
            routes.push({ route_uuid: route });
          } else if (route && route !== "undefined-undefined") {
            routes.push({ route_uuid: route.id || route });
          }
        });
      }
      if (this.promocode.route_type == "Schedule") {
        this.promocode.schedules.forEach((schedule) => {
          if (typeof schedule == "number") {
            schedules.push({ schedule_uuid: schedule });
          } else if (schedule && schedule !== "undefined-undefined") {
            schedules.push({ schedule_uuid: schedule.id || schedule });
          }
        });
      }
      if (!this.isInvalidData) {
        let data = {
          amount: this.promocode.amount * 100,
          amount_type: this.promocode.amount_type,
          route_type: this.promocode.route_type,
          routes: routes.length > 0 ? routes : [],
          schedules: schedules.length > 0 ? schedules : [],
          status: "Active",
          code: this.promocode.value,
          usage_limit: this.promocode.usage_limit
            ? this.promocode.usage_limit
            : null,
          started_at:
            this.promocode.dates.length > 0 &&
            this.promocode.dates[0] !== -1 &&
            this.promocode.dates[0] !== ""
              ? `${new Date(this.promocode.dates[0])
                  .toISOString()
                  .substring(0, 10)} 00:00:00`
              : `${new Date().toISOString().substring(0, 10)} 00:00:00`,
          ended_at:
            this.promocode.dates.length > 1 &&
            this.promocode.dates[1] !== -1 &&
            this.promocode.dates[1] !== ""
              ? `${new Date(this.promocode.dates[1])
                  .toISOString()
                  .substring(0, 10)} 23:59:00`
              : `${new Date(tenYears).toISOString().substring(0, 10)} 23:59:00`,
          translations: [
            {
              uuid: this.promocode?.translations?.[0]?.id,
              title: this.promocode.name,
              description: this.promocode.name,
              lang: "ua",
            },
          ],
        };
        let form = requestFormData.jsonToFormData(data);
        await promocodeService
          .updatePromocode(this.promocode.id, form)
          .then((res) => {
            if (res.status == "Success") {
              this.$emit("getPromocodes");
              this.$emit("close");
            }
          });
      }
    },
    setPromocode() {
      this.$set(
        this.promocode,
        "name",
        this.promocodeForEdit?.translations?.find(
          (translate) => translate.lang == "ua"
        )?.title
      );
      this.$set(this.promocode, "id", this.promocodeForEdit?.id);
      this.$set(this.promocode, "amount", this.promocodeForEdit?.amount);
      this.$set(
        this.promocode,
        "amount_type",
        this.promocodeForEdit?.amount_type
      );
      this.$set(this.promocode, "value", this.promocodeForEdit?.code);
      this.$set(this.promocode, "promocode_type", "Permanent");
      this.$set(this.promocode, "route_type", this.promocodeForEdit.route_type);
      this.$set(
        this.promocode,
        "translations",
        this.promocodeForEdit.translations
      );
      this.$set(this.promocode, "uuid", this.promocodeForEdit.uuid);
      setTimeout(() => {
        this.$set(
          this.promocode,
          "routes",
          this.promocodeForEdit?.routes.map(
            (route) => (route = route?.info?.id)
          )
        );
        this.$set(
          this.promocode,
          "schedules",
          this.promocodeForEdit?.schedules.map(
            (schedule) =>
              (schedule = { ...schedule.info, id: schedule.info?.id })
          )
        );
        if (this.promocodeForEdit.route_type == "Schedule") {
          this.$set(
            this.promocode,
            "routes",
            this.promocodeForEdit?.schedules.map(
              (schedule) => (schedule = schedule?.info?.route?.id)
            )
          );
        }
      }, 500);

      setTimeout(() => {
        this.$set(this.promocode, "dates", [
          this.promocodeForEdit.started_at.substring(0, 10),
          this.promocodeForEdit.ended_at.substring(0, 10),
        ]);
        this.datePickerKey++;
      }, 500);
    },
  },
  watch: {
    "promocode.promocode_type": {
      deep: true,
      handler() {
        if (this.promocode.promocode_type == "Permanent") {
          this.promocode.dates = [-1];
        } else {
          this.promocode.dates = ["", ""];
        }
      },
    },
    // "promocode.route_type": {
    //   deep: true,
    //   handler() {
    //     if (this.promocode.route_type == "All") {
    //       this.promocode.routes = [-1];
    //     } else {
    //       this.promocode.routes = [];
    //     }
    //   },
    // },
    "promocode.routes": {
      deep: true,
      handler() {
        if (this.promocode.route_type == "Schedule") {
          this.handleCheckRoute();
        }
      },
    },
  },
  computed: {
    nameError() {
      const errors = [];
      let field = this.$v.promocode.name;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    promocodeTypeError() {
      const errors = [];
      let field = this.$v.promocode.promocode_type;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    routeTypeError() {
      const errors = [];
      let field = this.$v.promocode.route_type;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    amountTypeError() {
      const errors = [];
      let field = this.$v.promocode.amount_type;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    amountError() {
      const errors = [];
      let field = this.$v.promocode.amount;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    valueError() {
      const errors = [];
      let field = this.$v.promocode.value;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    datesError() {
      const errors = [];
      let field = this.$v.promocode.dates;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    routesError() {
      const errors = [];
      let field = this.$v.promocode.routes;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    firstStepValid() {
      let field = this.$v.promocode;
      return (
        field.name.$invalid ||
        field.route_type.$invalid ||
        field.promocode_type.$invalid
      );
    },
    isInvalidData() {
      if (!this.isNextStep) {
        let field = this.$v.promocode;
        return (
          field.name.$invalid ||
          field.route_type.$invalid ||
          field.promocode_type.$invalid
        );
      } else {
        if (
          this.promocode.promocode_type == "Permanent" &&
          this.promocode.route_type == "All"
        ) {
          return (
            this.$v.promocode.name.$invalid ||
            this.$v.promocode.promocode_type.$invalid ||
            this.$v.promocode.route_type.$invalid ||
            this.$v.promocode.amount_type.$invalid ||
            this.$v.promocode.amount.$invalid
          );
        } else if (
          this.promocode.promocode_type == "Selected" &&
          this.promocode.route_type == "All"
        ) {
          return (
            this.$v.promocode.name.$invalid ||
            this.$v.promocode.promocode_type.$invalid ||
            this.$v.promocode.route_type.$invalid ||
            this.$v.promocode.amount_type.$invalid ||
            this.$v.promocode.amount.$invalid ||
            this.$v.promocode.dates.$invalid ||
            this.promocode.dates[0] == "" ||
            this.promocode.dates[1] == ""
          );
        } else if (
          this.promocode.promocode_type == "Permanent" &&
          this.promocode.route_type == "Selected"
        ) {
          return (
            this.$v.promocode.name.$invalid ||
            this.$v.promocode.promocode_type.$invalid ||
            this.$v.promocode.route_type.$invalid ||
            this.$v.promocode.amount_type.$invalid ||
            this.$v.promocode.amount.$invalid ||
            this.$v.promocode.routes.$invalid
          );
        }
      }
      return this.$v.$invalid;
    },
    minDate() {
      const today = new Date();
      const yesterday = new Date(today);
      return yesterday.toISOString().substr(0, 10);
    },
  },
};
</script>

<style scoped>
.promocodeActionBtn {
  width: 160px !important;
  padding: 12px 16px;
  height: 46px !important;
  border-radius: 10px;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 8px !important;
  text-transform: none !important;
}
</style>